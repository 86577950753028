/* eslint-disable */

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LOGO from '../../assets/Dx.png';
import axios from '../../context/axios';
import { AuthContext } from '../../context/context';
import { getUser, getUserPurchasing } from '../../context/fetch-service';
import { getIntegrationInfo, intuitSingleSignOn } from '../../context/intuit-service';
import { useStyles } from './styles.js';
import { myCompany } from '../../context/company-service.js';
import { Box, Card, Typography, Button, useMediaQuery, TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backgroundImage from '../../assets/grid-3.svg';
import warren from '../../assets/product-warren.svg';
import Intuit from "../../assets/intuit.svg";

const goToIntuit = async () => {
  const sub = await intuitSingleSignOn();
  if(sub.status === 200 || sub.status === 201){
    console.log(sub.data.url);
    window.location.href = sub.data.url
  }
}

function Login() {
  const classes = useStyles();
  const history = useNavigate();
  const { state: {
    companyName
  }, dispatch } = React.useContext(AuthContext);
  const [passwordType, setPasswordType] = React.useState('password');
  const [alertClass, setAlertClass] = React.useState('');
  const [err, setErr] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [loginForm, setLoginForm] = React.useState({
    email: '',
    password: '',
  });

  const handleLogin = (e) => {
    const { name, value } = e.target;
    setLoginForm((prevState) =>
    ({
      ...prevState,
      [name]: value,
    }));
  };  

  function groupPlansAndSumQuantities(subscriptionData) {
    const planMap = new Map();

    subscriptionData.forEach(subscription => {
      if (subscription.subscriptionDetails && subscription.subscriptionDetails.items && subscription.subscriptionDetails.items.data) {
        subscription.subscriptionDetails.items.data.forEach(item => {
          if (item.plan) {
            const planID = item.plan.id;
            const quantity = item.quantity;
            const isActive = item.plan.active;

            if (planMap.has(planID)) {
              const existingPlan = planMap.get(planID);
              existingPlan.quantity += quantity;
              existingPlan.active = existingPlan.active || isActive; // Set to true if either plan is active
            } else {
              planMap.set(planID, { plan: item.plan, quantity, active: isActive });
            }
          }
        });
      }
    });

    return Array.from(planMap.values());
  }

  function categorizePlans(subscriptionData) {
    const categorizedData = {
      clients: [],
      editors: []
    };

    const productMapping = {
      "prod_OhUTJRysPqIDJA": "clients",
      "prod_OhURofTlRdkPM8": "clients",
      "prod_OhUSqKIYaZvFxJ": "editors",
      "prod_OhUQO75zUu0V35": "editors"
    };

    subscriptionData.forEach(subscription => {
      if (subscription.subscriptionDetails && subscription.subscriptionDetails.items && subscription.subscriptionDetails.items.data) {
        subscription.subscriptionDetails.items.data.forEach(item => {
          if (item.plan && item.plan.product && productMapping[item.plan.product]) {
            const category = productMapping[item.plan.product];
            categorizedData[category].push({
              subscriptionID: subscription.subscriptionDetails.id,
              plan: item.plan,
              quantity: item.quantity
            });
          }
        });
      }
    });

    return categorizedData;
  }



  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const login = await axios.post('/login', loginForm);
      if (login.status === 200 || login.status === 201) {
        let [user, integrationInfoResponse, purchasing] = await Promise.all([
          getUser(login.data.token),
          getIntegrationInfo(),
          getUserPurchasing({logIn: true})
        ]);

        if (integrationInfoResponse) {
          dispatch({
            type: 'SET_INTEGRATIONINFO',
            payload: integrationInfoResponse.data.integrations,
          });
        }
        
        const companyDetails = await myCompany();
        if (companyDetails.data?.mycompany?.businessName) {
           
          dispatch({
            type: "SET_COMPANY_NAME",
            payload: companyDetails.data?.mycompany?.businessName,
          });
        }

        

        let data = groupPlansAndSumQuantities(purchasing);
        let planDetails = categorizePlans(purchasing)
        purchasing = { ...purchasing, planDetails }
        if (purchasing) {
          dispatch({
            type: 'SET_PURCHASING',
            payload: purchasing,
          });
        }

        // let data = groupPlansAndSumQuantities(purchasing);




        if (user) {
          setErr('');
          setAlertClass('show');
          setLoginForm({
            email: '',
            password: '',
          });
          dispatch({
            type: 'LOGIN',
            payload: {
              token: login.data.token,
              user,
              access_token:
                user.access_token && user.access_token.length > 0
                  ? user.access_token[0].access_token
                  : null,
            },
          });
          setLoader(false);
          window.location.href = '/clientperformance';
        }
      }
    } catch (e) {
      setAlertClass('show');
      if (e.response && e.response.data) {
        if (e.response.data.error) {
          setErr(e.response.data.error.message);
        } else if (e.response.data.message) {
          setErr(e.response.data.message.message);
        }
      } else {
        setErr(e.message);
      }
      setLoader(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertClass('hide');
    setErr('');
  };

  const handlePwdType = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "100%",
          // background: `url(${backgroundImage}) no-repeat right`,
          backgroundSize: "cover",
        }}
      >
        {/* Login Card */}
        <Card
          sx={{
            width: { xs: "100%", md: "70%" },
            padding: { xs: "30px", md: "60px" },
            backgroundColor: "#F8FAFC",
            boxShadow: 0,
          }}
        >
          <Box
            sx={{
              textAlign: "left",
              padding: "50px 10px",
              width: { xs: "80%", md: "50%" },
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                marginBottom: "100px",
              }}
            >
              <Link to="/">
                <img
                  src={LOGO}
                  alt="logo-black"
                  style={{
                    width: "130px",
                    height: "20px",
                    marginBottom: "30px",
                  }}
                />
              </Link>
            </Box>

            <Typography
              variant="h2"
              sx={{
                fontWeight: 900,
                fontSize: { xs: "36px", md: "46px" },
                color: "#55616E",
              }}
            >
              Sign in
            </Typography>
            <Typography sx={{ color: "#55616E", marginTop: 2 }}>
              New to Datatrixs? <Link to="/signup">Sign up</Link>.
            </Typography>

            {err && (
              <Box
                className={`alert alert-danger alert-dismissible fade ${alertClass}`}
                role="alert"
              >
                <strong>{err}</strong>
                <Button
                  onClick={handleCloseAlert}
                  type="button"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </Button>
              </Box>
            )}

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              {/* Email Input */}
              <TextField
                type="email"
                name="email"
                value={loginForm.email}
                onChange={handleLogin}
                required
                placeholder="Email"
                fullWidth
                variant="outlined"
                margin="normal"
              />

              {/* Password Input */}
              <TextField
                type={passwordType}
                name="password"
                value={loginForm.password}
                onChange={handleLogin}
                required
                placeholder="Password"
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handlePwdType} edge="end">
                        {passwordType === "password" ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Link
                to="/forgotpassword"
                style={{
                  display: "block",
                  textAlign: "right",
                  marginTop: "10px",
                }}
              >
                Forgot password?
              </Link>
              <Button
                type="submit"
                disabled={loader}
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  width: "100%",
                  mt: 3,
                }}
              >
                {loader ? "Loading..." : "Sign in"}
              </Button>

              {/* <Button
                type="button"
                disabled={loader}
                sx={{
                  backgroundColor: "#F8FAFC",
                  border: "1px solid",
                  borderColor: "grey.400",
                  color: "black",
                  width: "100%",
                  mt: 3,
                  "&:hover": {
                    backgroundColor: "#F4F6F8",
                    color: "black",
                  },
                }}
                startIcon={
                  <img
                    src={Intuit}
                    alt="Intuit"
                    style={{ width: "55px", height: "auto" }} 
                  />
                }
                onClick={goToIntuit}
              >
                {loader ? "Loading..." : `sign in`}
              </Button> */}
            </Box>
          </Box>
        </Card>

        {/* Warren Image */}
        <Card
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
            padding: 3,
            boxShadow: 0,
            background: `url(${backgroundImage}) no-repeat`,
          }}
        >
          <img
            src={warren}
            alt="warren"
            style={{ maxWidth: "80%", height: "auto" }}
          />
        </Card>
      </Box>
    </Box>
  );
}

export default Login;
