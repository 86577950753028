import React from 'react';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import FinproRoutes from './FinproRoutes';
import { AuthContext, initialState, reducer } from './context/context';
import { getCurrentUser } from './service';
import { Box } from '@mui/material';

// Temporary makeStyles import from @mui/material/styles for migration
// import { makeStyles } from 'tss-react/mui';

const PREFIX = 'App';

const classes = {
  root: `${PREFIX}-root`
};

const StyledStyledEngineProvider = styled(StyledEngineProvider)({
  [`& .${classes.root}`]: {
    // Add any styles here
    backgroundColor: '#f5f5f5',
  },
});

require('./RoundedBars');

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);


  // Function to toggle fullscreen
  const toggleFullScreen = () => {
    dispatch({ type: 'TOGGLE_FULLSCREEN' });
  };

  const inactivityTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
  let timeoutId;
  let lastResetTime = new Date();

  // Function to reset the timeout
  const resetInactivityTimeout = () => {
    clearTimeout(timeoutId);

    // const now = new Date();
    // // console.log(`Resetting inactivity timeout. Last reset was at: ${lastResetTime.toLocaleTimeString()}`);
    // lastResetTime = now;

    timeoutId = setTimeout(() => {
      dispatch({ type: 'LOGOUT' });
      window.location.href = '/';
    }, inactivityTimeout);
  };

  // Attach event listeners to monitor user activity
  React.useEffect(() => {
    const handleUserActivity = () => resetInactivityTimeout();

    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    // Start the initial timeout
    resetInactivityTimeout();

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeoutId); // Clear timeout on unmount
    };
  }, []);

  // Token expiration check
  React.useEffect(() => {
    const user = getCurrentUser();
    if (user && new Date(user.exp * 1000).getTime() < new Date().getTime()) {
      dispatch({ type: 'LOGOUT' });
      window.location.href = '/';
    }
  }, [dispatch]);

  return (
          <Box>
            {/* <Helmet>
              <style>{'body { background-color: white;} h1 { color: black; }'}</style>
            </Helmet> */}
            <AuthContext.Provider
              value={{
                state,
                dispatch,
                toggleFullScreen,
              }}
            >
              <FinproRoutes />
            </AuthContext.Provider>
          </Box>
  );
}

export default App;
