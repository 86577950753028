const GeneralBoardData = [
  {
    "boardData": [
      {
        "config": {
          "type": "bar",
          "data": {
            "labels": ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
            "datasets": [
              {
                "label": "Net Income (in Millions)",
                "data": [5, 7, 6.5, 8, 7.5, 9, 8.7, 9.2, 8.9],
                "backgroundColor": [
                  "rgba(44, 62, 80, 1)",
                  "rgba(52, 73, 94, 1)",
                  "rgba(41, 128, 185, 1)",
                  "rgba(39, 174, 96, 1)",
                  "rgba(192, 57, 43, 1)",
                  "rgba(142, 68, 173, 1)",
                  "rgba(22, 160, 133, 1)",
                  "rgba(243, 156, 18, 1)",
                  "rgba(211, 84, 0, 1)"
                ],
                "borderWidth": 1
              }
            ]
          },
          "options": {
            "maintainAspectRatio": false,
            "responsive": true,
            "scales": {
              "y": {
                "beginAtZero": true,
                "ticks": {
                  "color": "#666",
                  "callback": function(value) {
                    return value + "M";
                  }
                },
                "grid": {
                  "color": "rgba(0,0,0,0.1)"
                },
                "border": {
                  "color": "rgba(0,0,0,0.1)"
                }
              },
              "x": {
                "ticks": {
                  "color": "#666"
                },
                "grid": {
                  "color": "rgba(0,0,0,0.1)"
                },
                "border": {
                  "color": "rgba(0,0,0,0.1)"
                }
              }
            }
          }
        },
        "query": "What's the net income from January to September in a bar graph?",
      }
    ],
    "boardFormulas": [],
    "_id": "675233da81e2a01f1291bdc8",
    "boardType": "graph",
    "boardView": "graph",
    "index": 3,
    "invited": [],
    "title": "Net Income (Jan-Sep, Millions)"
  },
  {
    "boardData": [
      {
        "config": {
          "type": "line",
          "data": {
            "labels": [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September"
            ],
            "datasets": [
              {
                "label": "Net Income Margin (in %)",
                "data": [
                  15.2,
                  12.8,
                  14.6,
                  18.3,
                  16.5,
                  17.9,
                  19.4,
                  20.1,
                  21.7
                ],
                "borderColor": "rgba(75, 192, 192, 1)",
                "backgroundColor": "rgba(75, 192, 192, 0.5)",
                "borderWidth": 1,
                "fill": true
              }
            ]
          },
          "options": {
            "maintainAspectRatio": false,
            "responsive": true,
            "scales": {
              "y": {
                "axis": "y",
                "type": "linear",
                "beginAtZero": true,
                "ticks": {
                  "color": "#666"
                },
                "grid": {
                  "color": "rgba(0,0,0,0.1)"
                },
                "title": {
                  "display": true,
                  "text": "Net Income Margin (%)",
                  "color": "#666"
                }
              },
              "x": {
                "type": "category",
                "ticks": {
                  "color": "#666"
                },
                "grid": {
                  "color": "rgba(0,0,0,0.1)"
                },
                "title": {
                  "display": true,
                  "text": "Months",
                  "color": "#666"
                }
              }
            }
          }
        },
        "query": "Generate area chart for Net Income Margin from January to September"
      }
    ],
    "boardFormulas": [],
    "_id": "6752345a81e2a01f1291bdc9",
    "boardType": "graph",
    "title": "Net Income Margin (in %)",
    "boardView": "graph",
    "index": 4,
    "invited": []
  },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September"
              ],
              "datasets": [
                {
                  "label": "Debt-to-Equity Ratio",
                  "data": [
                    52.75,
                    43.33,
                    3.52,
                    11.68,
                    4.11,
                    64,
                    32.12,
                    51.35,
                    90.85
                  ],
                  "borderColor": "rgba(75, 72, 192, 1)",
                  "backgroundColor": "rgba(75, 72, 192, 0.2)",
                  "borderWidth": 1,
                  "fill": true
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "y",
                  "position": "left"
                },
                "x": {
                  "axis": "x",
                  "type": "category",
                  "ticks": {
                    "minRotation": 0,
                    "maxRotation": 50,
                    "mirror": false,
                    "textStrokeWidth": 0,
                    "textStrokeColor": "",
                    "padding": 3,
                    "display": true,
                    "autoSkip": true,
                    "autoSkipPadding": 3,
                    "labelOffset": 0,
                    "align": "center",
                    "crossAlign": "near",
                    "showLabelBackdrop": false,
                    "backdropColor": "rgba(255, 255, 255, 0.75)",
                    "backdropPadding": 2,
                    "color": "#666"
                  },
                  "display": true,
                  "offset": false,
                  "reverse": false,
                  "beginAtZero": false,
                  "bounds": "ticks",
                  "clip": true,
                  "grace": 0,
                  "grid": {
                    "display": true,
                    "lineWidth": 1,
                    "drawOnChartArea": true,
                    "drawTicks": true,
                    "tickLength": 8,
                    "offset": false,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "border": {
                    "display": true,
                    "dash": [],
                    "dashOffset": 0,
                    "width": 1,
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": false,
                    "text": "",
                    "padding": {
                      "top": 4,
                      "bottom": 4
                    },
                    "color": "#666"
                  },
                  "id": "x",
                  "position": "bottom"
                }
              }
            }
          },
          "query": "Generate area charts for Debt-to-Equity Ratio"
        }
      ],
      "boardFormulas": [],
      "_id": "6752329e81e2a01f1291bdc5",
      "boardType": "graph",
      "title": "Debt-to-Equity Ratio (Jan-Sep)",
      "boardView": "graph",
      "index": 0,
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>This template provides a clear and concise overview of essential financial metrics, including Revenue, Profit Margins, Cash Flow, Inventory Turnover, and ROI. It&rsquo;s designed to help you monitor performance, identify trends, and make informed decisions to drive growth and success.</p>\n<p>Empower your business with actionable insights at a glance!</p>"
        }
      ],
      "boardFormulas": [],
      "_id": "6752351381e2a01f1291bdca",
      "index": 5,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Financial Pulse Tracker",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": ["Q1", "Q2", "Q3"],
              "datasets": [
                {
                  "label": "Net Profit Margin (%)",
                  "data": [15.2, 18.7, 20.3],
                  "backgroundColor": [
                    "rgba(75, 192, 192)",
                    "rgba(153, 102, 255)",
                    "rgba(255, 159, 64)"
                  ],
                  "borderColor": [
                    "rgba(75, 192, 192)",
                    "rgba(153, 102, 255)",
                    "rgba(255, 159, 64)"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "x": {
                  "axis": "x",
                  "type": "category",
                  "display": true,
                  "title": {
                    "display": true,
                    "text": "Quarter",
                    "color": "#666"
                  }
                },
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "display": true,
                  "title": {
                    "display": true,
                    "text": "Net Profit Margin (%)",
                    "color": "#666"
                  }
                }
              }
            }
          },
          "query": "Graph the net profit margin for Q1, Q2, Q3 in a bar graph",
          "title": "Net Profit Margin by Quarter",
          "boardType": "graph",
          "boardView": "graph",
          "index": 6,
          "_id": "675a8de6ad4e003d3d386cbe",
          "invited": []
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Net Profit Margin by Quarter",
      "boardView": "graph",
      "index": 6,
      "_id": "675a8de6ad4e003d3d386cbe",
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>The Debt-to-Equity Ratio is a critical financial metric that measures the proportion of a company's debt to its shareholders' equity. It reflects the company&rsquo;s financial leverage and its ability to meet long-term financial obligations. A balanced ratio indicates prudent financial management, while a high ratio may signal increased financial risk.</p>\n<p>In this section, we analyze the Debt-to-Equity Ratio over time and across industry benchmarks, providing insights into the company&rsquo;s financial stability and capital structure. This information is vital for evaluating risk, optimizing financial strategy, and ensuring sustainable growth.</p>"
        }
      ],
      "boardFormulas": [],
      "_id": "675a8cccad4e003d3d386cbc",
      "index": 6,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Debt-to-Equity Ratio",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "line",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September"
              ],
              "datasets": [
                {
                  "label": "Gross Profit (in millions)",
                  "data": [
                    12.5,
                    15.2,
                    17.8,
                    14.3,
                    16.7,
                    18.9,
                    19.5,
                    20.1,
                    21.4
                  ],
                  "borderColor": "rgba(54, 162, 235, 1)",
                  "backgroundColor": "rgba(54, 162, 235, 0.2)",
                  "borderWidth": 2
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "scales": {
                "y": {
                  "beginAtZero": true,
                  "ticks": {
                    "color": "#666"
                  },
                  "grid": {
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": true,
                    "text": "Gross Profit (in millions)",
                    "color": "#666"
                  }
                },
                "x": {
                  "ticks": {
                    "color": "#666"
                  },
                  "grid": {
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": true,
                    "text": "Months",
                    "color": "#666"
                  }
                }
              }
            }
          },
          "query": "What's the gross profit for Q1, Q2, and Q3 2024 in a line graph?"
        }
      ],
      "boardFormulas": [],
      "boardType": "graph",
      "title": "Gross Profit (in millions)",
      "boardView": "graph",
      "index": 6,
      "_id": "675a8f00ad4e003d3d386cbf",
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "pie",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September"
              ],
              "datasets": [
                {
                  "label": "Total Expenses",
                  "data": [
                    31.16,
                    10.67,
                    68.74,
                    44.58,
                    13.08,
                    50.02,
                    4.4,
                    91.02,
                    26.62
                  ],
                  "backgroundColor": [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4BC0C0",
                    "#9966FF",
                    "#FF9F40",
                    "#E7E9ED",
                    "#71B37C",
                    "#FF0000"
                  ],
                  "hoverBackgroundColor": [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4BC0C0",
                    "#9966FF",
                    "#FF9F40",
                    "#E7E9ED",
                    "#71B37C",
                    "#FF0000"
                  ]
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "plugins": {
                "legend": {
                  "display": true,
                  "position": "top"
                }
              }
            }
          },
          "query": "Graph the total expenses from January to September in a pie chart"
        }
      ],
      "boardFormulas": [],
      "_id": "675a8f00ad4e003d3d386cbf",
      "boardType": "graph",
      "title": "Total Expenses (January to September)",
      "boardView": "graph",
      "index": 7,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September"
              ],
              "datasets": [
                {
                  "label": "Gross Profit Margin (%)",
                  "data": [
                    55.4,
                    60.2,
                    48.9,
                    70.1,
                    65.3,
                    58.6,
                    62.4,
                    74.2,
                    69.8
                  ],
                  "backgroundColor": [
                    "#4BC0C0",
                    "#FF6384",
                    "#FFCE56",
                    "#36A2EB",
                    "#9966FF",
                    "#FF9F40",
                    "#71B37C",
                    "#FF6384",
                    "#36A2EB"
                  ],
                  "borderColor": [
                    "#4BC0C0",
                    "#FF6384",
                    "#FFCE56",
                    "#36A2EB",
                    "#9966FF",
                    "#FF9F40",
                    "#71B37C",
                    "#FF6384",
                    "#36A2EB"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "plugins": {
                "legend": {
                  "display": true,
                  "position": "top"
                },
              },
              "scales": {
                "y": {
                  "axis": "y",
                  "type": "linear",
                  "beginAtZero": true,
                  "ticks": {
                    "color": "#666"
                  },
                  "grid": {
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": true,
                    "text": "Gross Profit Margin (%)",
                    "color": "#666"
                  }
                },
                "x": {
                  "type": "category",
                  "ticks": {
                    "color": "#666"
                  },
                  "grid": {
                    "color": "rgba(0,0,0,0.1)"
                  },
                  "title": {
                    "display": true,
                    "text": "Months",
                    "color": "#666"
                  }
                }
              }
            }
          },
          "query": "Graph the gross profit margin from January to September in a bar graph"
        }
      ],
      "boardFormulas": [],
      "_id": "675a8f00ad4e003d3d386cbf",
      "boardType": "graph",
      "title": "Gross Profit Margin (January to September)",
      "boardView": "graph",
      "index": 8,
      "invited": []
    },
    {
      "boardData": [
        {
          "config": {
            "type": "bar",
            "data": {
              "labels": [
                "Current Quick Ratio"
              ],
              "datasets": [
                {
                  "label": "Quick Ratio",
                  "data": [
                    1.5  // Update this value with the current quick ratio value you want to display
                  ],
                  "backgroundColor": [
                    "#36A2EB"
                  ],
                  "borderColor": [
                    "#36A2EB"
                  ],
                  "borderWidth": 1
                }
              ]
            },
            "options": {
              "maintainAspectRatio": false,
              "responsive": true,
              "plugins": {
                "tooltip": {
                  "enabled": true
                },
                "legend": {
                  "display": true,
                  "position": "top"
                }
              },
              "scales": {
                "y": {
                  "beginAtZero": true,
                  "title": {
                    "display": true,
                    "text": "Quick Ratio"
                  }
                },
                "x": {
                  "title": {
                    "display": true,
                    "text": "Metric"
                  }
                }
              }
            }
          },
          "query": "What's my current quick ratio as a bar graph?",
        }
      ],
      "boardFormulas": [],
      "_id": "675a8f00ad4e003d3d386cbf",
      "boardType": "graph",
      "title": "Current Quick Ratio",
      "boardView": "graph",
      "index": 8,
      "invited": []
    },
    {
      "boardData": [
        {
          "text": "<p>The Quick Ratio is an essential metric for assessing liquidity and understanding a company’s financial health. It provides a clear indication of whether a business can pay off its short-term liabilities without relying on inventory sales. A higher Quick Ratio signals efficient management of cash and receivables, while a lower ratio may point to potential liquidity challenges or over-reliance on less liquid assets.</p>\n<p>This ratio is critical for making informed financial decisions and preparing for unexpected financial challenges. By regularly monitoring the Quick Ratio, businesses and investors can evaluate operational efficiency and ensure the company remains resilient, adaptable, and well-positioned for future growth opportunities.</p>"
        }
      ],
      "boardFormulas": [],
      "_id": "6752351381e2a01f1291bdca",
      "index": 10,
      "boardView": "Text",
      "boardType": "Text",
      "title": "Quick Ratio",
      "invited": []
    }                
  ]

const GeneralLayout = [
    {
      "w": 6,
      "h": 3,
      "x": 4,
      "y": 3,
      "i": "0",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 10,
      "h": 3,
      "x": 0,
      "y": 9,
      "i": "1",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 7,
      "h": 3,
      "x": 3,
      "y": 6,
      "i": "2",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 0,
      "i": "3",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 3,
      "x": 0,
      "y": 3,
      "i": "4",
      "minW": 2,
      "minH": 2,
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 3,
      "x": 0,
      "y": 6,
      "i": "5",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "6",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 0,
      "y": 10,
      "i": "7",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 3,
      "x": 5,
      "y": 10,
      "i": "8",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 6,
      "h": 3,
      "x": 5,
      "y": 11,
      "i": "9",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 3,
      "x": 0,
      "y": 11,
      "i": "10",
      "minW": 2,
      "minH": 3,
      "moved": false,
      "static": false
    }
  ]
export { GeneralBoardData, GeneralLayout };